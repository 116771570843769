import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material';
import '../../globals/services-styles.css';
import { ContServiciosPage, BtnOnbard, ContButton, IndentityPhoneImg } from './ServiciosPage.styles';
import { IdentityCont, ContTextIdentity, TextIdentitySummary, ContCardIdentity, ContOnb, ContHowWork, TitleHowWork, ContTextDescHowWork, TextDescHowWork } from '../globalpages.styles';
import ButtonSpecial from '../../components/ButtonSpecial';
import ActionDemo from '../../components/ActionDemo/index';
import phoneIdentity from '../../assets/images/Servicios/cell-identity.webp';
import BiometricFace from '../../assets/images/Servicios/onboarding-img-services.webp';
import StepsServices from '../../components/StepsServices/index';
import ServicesIntegrations from '../../components/ServicesIntegrations/index';
import ServicesBeneficios from '../../components/ServicesBeneficios/index';
import CCIntegration from '../../assets/images/Servicios/circulo-hover.webp';
import Truora from '../../assets/images/Servicios/truora_hover.webp';
import NaaT from '../../assets/images/Servicios/naat-services-height.webp';
const BeneficiosItems = [
  {
    title: 'Mejora la experiencia del cliente',
  },
  {
    title: 'Optimiza y agiliza proceso de integración',
  },
  {
    title: 'Recaba documentos digitales',
  },
  {
    title: 'Validación OCR INE/IFE, CURP y RFC ',
  },
  {
    title: 'Consulta historial y score crediticio',
  },
  {
    title: 'Cumple normativas de PLD',
  },
  {
    title: 'Alertas en Listas Negras',
  },
];
const stepsServicios = [
  { number: 1, title: 'Registro del cliente', subtitle: 'El cliente crea una cuenta' },
  { number: 2, title: 'Validación', subtitle: 'Llena el formulario con su información personal.' },
  { number: 3, title: 'Verificación de datos', subtitle: 'Escaneo de Identificación y rostro.'},
  { number: 4, title: 'Registro exitoso', subtitle: 'Proceso completado' },
];

export const ServiciosPage = () => {
  const { t } = useTranslation();
  const imagesToShow = [
    { src: CCIntegration, visible: true, alt: 'Logo circulo de crédito', },
    { src: Truora, visible: true, alt: 'Logo Truora', },
    { src: NaaT, visible: true, alt: 'Logo Naat', },
  ];
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <ContServiciosPage id={'ContServiciosPage'}>

      <ContCardIdentity id={'ContCardIdentity'}>
        <IdentityCont>
          <Grid container spacing={2} className={'ContCardIdentity GridBlueCard'}>
            <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 1 }} style={{paddingLeft: '5%'}}>
              <ContOnb>
                <BtnOnbard>
                  {t('Onboarding Digital')}
                </BtnOnbard>
              </ContOnb>
              <ContTextIdentity>
              {t('Integra y verifica la identidad de tus clientes en segundos.')}
              </ContTextIdentity>
              <TextIdentitySummary>
              {t('Agiliza la integración de nuevos clientes, solicitando información de manera digital y validándola con el método más seguro.')}
              </TextIdentitySummary>
              <ContButton>
                <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'secondary'}/>
              </ContButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} 
              order={{ xs: 2, sm: 2, md: 2 }}
              className={'GridStylesCardImage'}
            >
              <IndentityPhoneImg src={phoneIdentity} alt={'Onboarding digital'} width={230}/>
            </Grid>
          </Grid>
        </IdentityCont>
      </ContCardIdentity>

      <ContHowWork id={'ContHowWork'}>
        <TitleHowWork>
        {t('¿Cómo funciona?')}
        </TitleHowWork>
        <ContTextDescHowWork>
          <TextDescHowWork>
          {t('Recaba toda la información que necesites de tus clientes, información personal, documentos oficiales y realiza un escáner facial para la autenticación de identidad.')}
          </TextDescHowWork>
        </ContTextDescHowWork>

        <StepsServices steps={stepsServicios} version={2}/>

        <ContButton>
          <ButtonSpecial text={'Prueba la versión Demo'} type={'/'} linkTo={'/contacto'} target={'_self'} theme={'primary'}/>
        </ContButton>
      
      </ContHowWork>

      <ServicesBeneficios items={BeneficiosItems} image={BiometricFace} alt={'Verificación de identidad'}/>

      <ServicesIntegrations imagesToShow={imagesToShow}/>

      <ActionDemo />
    </ContServiciosPage>
  )
}

import {useState} from 'react';

const url = 'https://xi98869mj1.execute-api.us-west-2.amazonaws.com/v1/email-sender';

export const useFormContact = () => {
  const [erName, setErName] = useState('');
  const [erCompany, setErCompany] = useState('');
  const [erEmail, setErEmail] = useState('');
  const [erPhone, setErPhone] = useState('');
  const [erInterest, setErInterest] = useState('');
  const [erMessage, setErMessage] = useState('');
  const [respSend, setRespSend] = useState({
    openAlert: false,
    status: '',
    message: '',
  });

  const onSubmit = (form) => {
    const payload = form;
    if ( payload !== undefined) {
      post(url, payload, function (err, res) {

        if ( err ) {
          success(0, err);
          return error(err);
        } else {
          success(1);
        }
      });
      success(1);
      return true;
    } else {
      //success(1, 'Error');
      return false;
    }
  }

  const onFormValidate = (form) => {
    // console.log('onFormValidate => form =>', form);
    const {name, company, email, phone, interest, message} = form;

    if ( name === null || name.length === 0 || /^\s+$/.test(name) ) {
      setErName('Tu nombre es indispensable');
      // return false;
    }

    if ( interest === null || interest.length === 0 || /^\s+$/.test(interest) ) {
      setErInterest('Seleccione una opción');
      // return false;
    }

    if ( message === null || message.length === 0 || /^\s+$/.test(message) ) {
      setErMessage('Ayúdanos a conocer más');
      // return false;
    }

    if ( email === null || email.length === 0 || /^\s+$/.test(email) ) {
      setErEmail('Para continuar es necesario tu email');
      // return false;
    } else {
      if ( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) ) {
        let formatPhone = /[0-9]{10}/;
        if ( !formatPhone.test(phone) ) {
          setErPhone('Escriba un número de teléfono');
          // return false;
        }

        return {
          name: name,
          email: email,
          company: company,
          phone: phone,
          interest: interest,
          message: message,
          subject: interest,
        };
      } else {
        setErEmail('Email mal escrito (email@tudominio.com)');
        // return false;
      }
    }
  }

  const post = (url, body, callback) => {
    let req = new XMLHttpRequest();
    req.open('POST', url, true);
    req.setRequestHeader('Content-Type', 'application/json');
    req.addEventListener('load', function () {
      if ( req.status < 400 ) {
        callback(null, JSON.parse(req.responseText));
      } else {
        callback(new Error('Request failed: ' + req.statusText));
      }
    });
    req.send(JSON.stringify(body));
  }

  const error = (err) => {
    console.log(err);
  }

  const success = (s, e = {}) => {
    if (s) {
      setRespSend({
        openAlert: true,
        status: 'success',
        message: `<div>Tus datos han sido enviados correctamente, en breve <strong className="mr-auto">un asesor se contactara contigo.</strong></div>`,
      });
    } else {
      setRespSend({
        openAlert: true,
        status: 'error',
        message: `<div>${e}</div>`,
      });
    }
  }

  const closeAlert = () => {
    setRespSend({
      ...respSend,
      openAlert: false,
    });
  }

  return {
    onSubmit,
    erName,
    erCompany,
    erEmail,
    erPhone,
    erInterest,
    erMessage,
    respSend,
    closeAlert,
  };
};

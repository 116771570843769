import React from 'react';
import Slider from 'react-slick';
import {CarouselContainer, CarouselImage} from './CientsCarousel.styles';

import truora from '../../assets/images/UseCases/truora_logo.svg';
import quienesquien from '../../assets/images/UseCases/quien-es-quien.png';
import stp from '../../assets/images/UseCases/stp.png';
import circulo from '../../assets/images/UseCases/circulo-credito.png';
import tiaxa from '../../assets/images/UseCases/tiaxa.png';
import visa from '../../assets/images/UseCases/visa.png';
import naat from '../../assets/images/UseCases/naat.png';

const images = [truora, quienesquien, stp, circulo, tiaxa, visa, naat];

const settings = {
  dots: false,
  infinite: true,
  autoplay: true, // Activa la reproducción automática
  speed: 2000,
  autoplaySpeed: 1000,
  slidesToShow: 6, // Muestra 6 imágenes a la vez
  slidesToScroll: 1, // Desplaza una imagen a la vez
  cssEase: "linear",
  rtl: false
};

const ClientCarousel = () => {
  return (
    <CarouselContainer>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <CarouselImage src={image} alt={`Imagen ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </CarouselContainer>
  );
};

export default ClientCarousel;
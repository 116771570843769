import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContButton,
  ContMarketPlaceSummary, ContSlidersMarketPlaceSummary,
  TextMarketPlaceSummary,
  TitleMarketPlaceSummary,
} from './MarketPlaceSummary.styles';
import {Grid} from '@mui/material';
import ButtonSpecial from '../ButtonSpecial';
import SliderSlick from '../SliderSlick';
import {useWindowDimensions} from '../../utils';

import Img1 from '../../assets/images/truora-card.png';
import Img2 from '../../assets/images/circulo-de-credito.webp';
import Img3 from '../../assets/images/na-at.webp';
import Img4 from '../../assets/images/quien-es-quien.webp';
import Img5 from '../../assets/images/buro-de-credito.webp';
import Img6 from '../../assets/images/tiaxa.webp';
import Img7 from '../../assets/images/sat.webp';
import Img8 from '../../assets/images/pagaqui.webp';
import Img9 from '../../assets/images/openpay.webp';
import Img10 from '../../assets/images/oxxopay.webp';
import Img11 from '../../assets/images/stp.webp';
import Img12 from '../../assets/images/whatsapp.webp';
import Img13 from '../../assets/images/aws.webp';
import Img14 from '../../assets/images/visa-mastercard.webp';

const Slider1 = [
  {
    name: 'Logo Truora',
    img: Img1
  },
  {
    name: 'Logo Círculo de Crédito',
    img: Img2
  },
  {
    name: 'Logo NA-AT',
    img: Img3
  },
  {
    name: 'Logo Quién es Quién',
    img: Img4
  },
  {
    name: 'Logo Buró de Crédito',
    img: Img5
  },
  {
    name: 'Logo Tiaxa',
    img: Img6
  },
  {
    name: 'Logo SAT',
    img: Img7
  },
  {
    name: 'Logo Pagaqui',
    img: Img8
  },
  {
    name: 'Logo Openpay',
    img: Img9
  },
  {
    name: 'Logo OXXO Pay',
    img: Img10
  },
  {
    name: 'Logo STP',
    img: Img11
  },
  {
    name: 'Logo WhatsApp',
    img: Img12
  },
  {
    name: 'Logo AWS',
    img: Img13
  },
  {
    name: 'Logo VISA/MASTERCARD',
    img: Img14
  },
];

export const MarketPlaceSummary = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const AUTOPLAY = true;
  const slidesToShow = width >= 1024 ? 2.8 : width >= 768 ? 2.6 : 2.4;

  return (
    <ContMarketPlaceSummary className={'container-fit'}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={7} order={{ xs: 2, sm: 2, md: 1 }}>
          <ContSlidersMarketPlaceSummary>
            <SliderSlick
              data={Slider1}
              autoplay={AUTOPLAY}
              rtl={false}
              dots={false}
              infinite={true}
              slidesToShow={slidesToShow}
              slidesToScroll={1}
              speed={2000}
              autoplaySpeed={2000}
              arrows={false}
            />
            <SliderSlick
              data={Slider1}
              autoplay={AUTOPLAY}
              rtl={true}
              dots={false}
              infinite={true}
              slidesToShow={slidesToShow}
              slidesToScroll={1}
              speed={2000}
              autoplaySpeed={2000}
              arrows={false}
            />
          </ContSlidersMarketPlaceSummary>
        </Grid>
        <Grid item xs={12} sm={12} md={5} order={{ xs: 1, sm: 1, md: 2 }}>
          <TitleMarketPlaceSummary>
          {t('Todo en una plataforma')}
          </TitleMarketPlaceSummary>
          <TextMarketPlaceSummary>
          {t('Creamos un marketplace de proveedores especializados en la creación de productos financieros, recepción de solicitudes, aprobación de créditos y administración de cartera.')}
          </TextMarketPlaceSummary>
          <ContButton>
            <ButtonSpecial text={'Ir al marketplace'} type={'/'} linkTo={''} theme={'secondary'}/>
          </ContButton>
        </Grid>
      </Grid>
    </ContMarketPlaceSummary>
  )
}
